import React, { useState } from 'react';
import {Input, FormFeedback, Alert} from "reactstrap";
import postApi from "../../apis/post.api";
import { Link, useLocation, useParams } from "react-router-dom";
import {toast} from "react-toastify";
import { handleError, handleSuccess } from 'common/commonFunction';

const ResetPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const { token } = useParams();
    const location = useLocation();

    const getQueryParam = (param: string) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param); // Returns the value of the query param, or null if it doesn't exist
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setPasswordError('');
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(e.target.value);
        setConfirmPasswordError('');
    };

    const validatePasswords = () => {
        let isValid = true;
        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            isValid = false;
        }
        if (password !== passwordConfirmation) {
            setConfirmPasswordError('Passwords do not match');
            isValid = false;
        }
        return isValid;
    };

    const reset = () => {
        if (!validatePasswords()) return;

        setError('');
        setLoading(true);
        postApi('/reset-password', {
            token: token,
            email: getQueryParam('email'),
            password,
            password_confirmation: passwordConfirmation,
            type : "r"
        }).then((response) => {
            handleSuccess(response)
            setTimeout(() => {
                window.location.href = "/login"
            },3000)
        }).catch(error => {
            let message = 'Something went wrong while processing your request, please try again';
            if (error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setError(message);
            handleError(error);
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className="w-100 mt-5">
                <div className="justify-content-center row">
                    <div className="col-12 col-xxl-4 col-xl-4 col-lg-6 col-md-8">
                        <div className="card">
                            <div className="card-body text-center">
                                <Link to={"/"}>
                                    <img alt="defualt"
                                         src={"https://clvrpay.com/static/media/logo.407eac689daf8d980d7c.png"}
                                         className="w-100"/>
                                </Link>
                                <p className="fs-2 text-muted">
                                    You can now reset your password
                                </p>
                                {error && <Alert className="mt-3 text-start" color={"danger"}>{error}</Alert>}
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon2">
                                            <i className="mdi mdi-key"></i>
                                        </span>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            placeholder="Enter New Password"
                                            aria-label="Enter New Password"
                                            aria-describedby="basic-addon2"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            invalid={!!passwordError}
                                        />
                                        {passwordError && <FormFeedback>{passwordError}</FormFeedback>}
                                    </div>
                                </div>
                                <div className="form-group mt-2">
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon2">
                                            <i className="mdi mdi-key"></i>
                                        </span>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            aria-label="Confirm Password"
                                            aria-describedby="basic-addon2"
                                            value={passwordConfirmation}
                                            onChange={handleConfirmPasswordChange}
                                            invalid={!!confirmPasswordError}
                                        />
                                        {confirmPasswordError && <FormFeedback>{confirmPasswordError}</FormFeedback>}
                                    </div>
                                </div>
                                <div className="mt-1 text-start">
                                    <Link to="/reset/my/password" className="text-muted">
                                        Resend forget password link?
                                    </Link>
                                </div>
                                <div className="form-group mt-2">
                                    <button
                                        className="btn btn-md btn-primary w-100"
                                        onClick={reset}
                                        disabled={loading || password !== passwordConfirmation || password.length < 6}
                                    >
                                        {loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                        ) : (
                                            "Reset Password"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ResetPasswordPage;
