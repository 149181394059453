import getApi from "apis/get.api";
import putApi from "apis/put.api";
import { handleError, handleSuccess } from "common/commonFunction";
import { useState } from "react";
import { toast } from "react-toastify";
import { Container, Input, Label } from "reactstrap";

const RedeemPayout = () => {
    const [voucherCode, setVoucherCode] = useState('');
    const [voucher, setVoucher] = useState<any>(null);

    const handleFilterChange = (e : any) => {
        setVoucherCode(e.target.value);
    };

    const fetchVouchers = () => {
        setVoucher(null);
        getApi(`/reseller/voucher/fetch/${voucherCode}`).then((response) => {
            setVoucher(response.data.data);
        })
        .catch((error) => {
            handleError(error);
        })
        .finally(() => {
            // setLoading(false);
        });
    };

    const redeem = () => {
        putApi(`/reseller/voucher/redeem/${voucherCode}`,{}).then((response) => {
            handleSuccess(response);
            setVoucherCode('');
            setVoucher(null);
        }).catch((error) => {
            handleError(error);
        });
    }

    return <div className="page-content">
        <Container fluid={true}>
            <div className="card fw-bold">
                <div className="card-header">
                    <div className="d-flex">
                        <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                        <div className="input-group ">
                            <div className="input-group-text">Voucher Code</div>
                            <Input type="text" className="form-control" id="voucherCode" value={voucherCode} onChange={handleFilterChange} placeholder="Voucher Code" />
                        </div>
                        <button className="btn btn-primary ms-2" onClick={fetchVouchers}>Search</button>
                    </div>
                </div>
                <div className="card-body">
                    {voucher && <dl className="row mb-0">
                        <dt className="col-sm-3">Voucher Code</dt>
                        <dd className="col-sm-9">{voucher.code}</dd>

                        <dt className="col-sm-3">Voucher Amount</dt>
                        <dd className="col-sm-9">{voucher.amount} {voucher.currency}</dd>

                        <dt className="col-sm-3">Voucher Creation Date</dt>
                        <dd className="col-sm-9">{voucher.created_at}</dd>

                        <dt className="col-sm-3 text-truncate">Voucher Expiry Date</dt>
                        <dd className="col-sm-9">{voucher.expired_at}</dd>

                        <dt className="col-sm-3 text-truncate">ClvrPay Voucher</dt>
                        <dd className="col-sm-9">{voucher.is_clvrpay ? 'Yes' : 'No'}</dd>

                        <dt className="col-sm-3 text-truncate">Voucher Type</dt>
                        <dd className="col-sm-9">{voucher.type.toUpperCase()}</dd>

                        <dt className="col-sm-3 text-truncate">Merchant</dt>
                        <dd className="col-sm-9">{voucher.merchant.toUpperCase()}</dd>
                    </dl>}
                    {!voucher && <div className="text-center p-5">No voucher found with the given code.</div>}
                </div>
                {voucher && <div className="card-footer">
                    <button className="btn btn-primary btn-md" onClick={redeem}>
                        Redeem
                    </button>
                </div>}
            </div>
        </Container>
    </div>

}
export default RedeemPayout;