import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
    Label,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";
import { handleError, handleSuccess } from 'common/commonFunction';

const ListTransactions = () => {
    document.title = "Wallet | Account Statement";
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [transactionId, setTransactionId] = useState<string>("");
    const [transactionType, setTransactionType] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [transactionAmount, setTransactionAmount] = useState<string>("");

    const getList = (page : number,queryParams ?: string) => {
        setLoading(true);
        let url = `/reseller/transactions/list?page=${page}`;
        if(queryParams){
            url += url + '&' + queryParams
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setTransactions(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the wallet transactions list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getList(currentPage);
    }, [currentPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const clearFilter = () => {
        setTransactionAmount('')
        setStartDate('')
        setEndDate('')
        setTransactionId('')
        getList(1)
    }

    const filterByData = (page ?: any) => {
        let query = '';

        if (startDate || endDate) {
            // Get today's date
            const today = new Date();
            const formattedToday = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Calculate the date 3 months ago
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Use provided dates or defaults
            const start = startDate || formattedThreeMonthsAgo;
            const end = endDate || formattedToday;
            query = `&start=${start}&end=${end}`
        }

        if(transactionId) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `transaction_id=` + transactionId
        }

        if(transactionAmount) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `amount=` + transactionAmount
        }

        if(transactionType) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `type=` + transactionType
        }

        if(page) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `page=` + page
        }
        getList(1,query)
    };

    const exportResult = () => {
        let query = '';

        if (startDate || endDate) {
            // Get today's date
            const today = new Date();
            const formattedToday = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Calculate the date 3 months ago
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const formattedThreeMonthsAgo = threeMonthsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

            // Use provided dates or defaults
            const start = startDate || formattedThreeMonthsAgo;
            const end = endDate || formattedToday;
            query = `start=${start}&end=${end}`
        }

        if(transactionId) {
            let append = '';
            if(query){
                append = '&';
            }
            query = append + `transaction_id=` + transactionId
        }

        if(transactionAmount) {
            let append = '';
            if(query){
                append = '&';
            }
            query = append + `amount=` + transactionAmount
        }

        if(transactionType) {
            let append = '';
            if(query){
                append = '&'
            }
            query = append + `type=` + transactionType
        }

        exportResultsList(query)
    };

    const exportResultsList = (queryParam = '') => {
        getApi(`/reseller/transactions/list/export?${queryParam}`).then((response) => {
            handleSuccess(response)
        }).catch((error) => {
            handleError(error);
        })
    };

    const getTransactionType = (transactionType : string,customTransaction : string) => {
        let type = ''
        if(customTransaction){
            switch (customTransaction) {
                case 'payout':
                    type = 'Payout Fund Wallet';
                    break;
                case 'generate':
                    type = 'Generate Voucher'
                    break;
                case 'redeem':
                    type = 'Redeem Voucher'
                    break;
                case 'settlement':
                    type = 'Settlement'
                    break;
                case 'merchant_reseller_deduct':
                    type = 'Deduct By Merchant'
                    break;
            }
        }else {
            switch (transactionType) {
                case 'fund':
                    type = 'Fund Wallet';
                    break;
                case 'deduct':
                    type = 'Generate Voucher'
                    break;
            }
        }
        return type;
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Statemenet</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all wallet transactions that has been made on your account
                                </div>
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-11 col-md-6 col-lg-2 col-xl-2">
                                            <Label>Transaction ID</Label>
                                            <input
                                                type="text"
                                                value={transactionId}
                                                onChange={(e) => setTransactionId(e.target.value)}
                                                className="form-control"
                                                placeholder="Transaction ID"
                                            />
                                        </div>
                                        <div className="col-11 col-md-6 col-lg-3 col-xxl-2">
                                            <Label>Transaction Type</Label>
                                            <select
                                                className="form-control"
                                                onChange={(e) => setTransactionType(e.target.value)}
                                            >
                                                <option value="" disabled>
                                                    Select
                                                </option>
                                                <option value="">All Types</option>
                                                <option value="fund">Fund Wallet</option>
                                                <option value="deduct">Generate Voucher</option>
                                                <option value="payout">Payout</option>
                                            </select>
                                        </div>
                                        <div className="col-11 col-md-6 col-lg-3 col-xxl-2">
                                            <Label>Date From</Label>
                                            <input
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-11 col-md-6 col-lg-3 col-xxl-2">
                                            <Label>Date To</Label>
                                            <input
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-11 col-md-6 col-lg-3 col-xxl-2">
                                            <Label>Clear Filter</Label>
                                            <button className="btn btn-danger w-100" onClick={clearFilter}>
                                                Clear Filter
                                            </button>
                                        </div>
                                        <div className="col-11 col-md-6 col-lg-3 col-xl-2">
                                            <Label>Apply Filter</Label>
                                            <button className="btn btn-primary w-100" onClick={filterByData}>
                                                Apply Filter
                                            </button>
                                        </div>
                                        <div className="col-11 col-md-6 col-lg-4 col-xxl-3">
                                            <Label>Export Results</Label>
                                            <button className="btn btn-success w-100" onClick={exportResult}>
                                                Export Results To CSV
                                            </button>
                                        </div>
                                    </div>
                                    <div className="table-responsive table-card mt-3 fw-bold">
                                        <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                                <tr >
                                                    <th scope="col" className={"text-start"}>Transaction Unique ID</th>
                                                    <th scope="col">Transaction ID</th>
                                                    <th scope="col">Transaction Amount</th>
                                                    <th scope="col">Transaction Currency</th>
                                                    <th scope="col">Transaction Type</th>
                                                    <th scope="col">Balance Before</th>
                                                    <th scope="col">Balance After</th>
                                                    <th scope="col">Transaction Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}><Spinner /></td>
                                                </tr>
                                            )}
                                            {transactions && transactions.length > 0 && transactions.map((transaction : any) => (
                                                <tr key={transaction.transactionUniqueId} className={"fw-bold"}>
                                                    <td className={"text-start"}><span
                                                        className="fw-medium">{transaction.transactionUniqueId}</span>
                                                    </td>
                                                    <td>{transaction.transactionId}</td>
                                                    <td className={`${transaction.transactionType === 'deduct' ? 'text-danger' : 'text-success'}`}>{transaction.transactionType === 'deduct' ? '-' : '+'}{transaction.transactionAmount}</td>
                                                    <td>{transaction.transactionCurrency.toString().toUpperCase()}</td>
                                                    <td>{getTransactionType(transaction.transactionType,transaction.transactionCustomType)}</td>
                                                    <td>{transaction.transactionAmountBefore}</td>
                                                    <td>{transaction.transactionAmountAfter}</td>
                                                    <td>{transaction.transactionDate}</td>
                                                </tr>
                                            ))}
                                            {!loading && transactions.length === 0 && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}>No Transactions Created Yet</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            {/* Previous Button */}
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous
                                                                onClick={() => handlePageChange(currentPage - 1)}/>
                                            </PaginationItem>

                                            {/* Pagination Numbers */}
                                            {[...Array(lastPage)].map((_, i) => {
                                                // Display first two pages, last two pages, and pages near the current page
                                                const pageNumber = i + 1;
                                                const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                                                const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                                                if (isNearCurrentPage || isFirstOrLastPage) {
                                                    return (
                                                        <PaginationItem key={pageNumber}
                                                                        active={currentPage === pageNumber}>
                                                            <PaginationLink
                                                                onClick={() => handlePageChange(pageNumber)}>
                                                                {pageNumber}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                }
                                                // Ellipsis for skipped pages
                                                if (pageNumber === 2 && currentPage > 4) {
                                                    return <PaginationItem key="ellipsis1"
                                                                        disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }
                                                if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                                                    return <PaginationItem key="ellipsis2"
                                                                        disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }

                                                return null; // Don't render other page numbers
                                            })}

                                            {/* Next Button */}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ListTransactions;