import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Basic404 from "pages/AuthenticationInner/Errors/Basic404";
import CreateVoucher from "../project-pages/vouchers/create/CreateVoucher";
import ListVoucher from "../project-pages/vouchers/list/ListVoucher";
import Dashboard from "../project-pages/dashboard/Dashboard";
import ListTransactions from "../project-pages/wallet/ListTransactions";
import Profile from "../project-pages/profile/Profile";
import Register from "../pages/Authentication/Register";
import EmailPinCodeVerification from "../pages/Authentication/EmailPinCodeVerification";
import RedeemPayout from "project-pages/payout/RedeemPayout";
import ResetMyPassword from "../project-pages/password/ResetMyPassword";
import ResetPasswordPage from "../project-pages/password/ResetPasswordPage";

const authProtectedRoutes = [
  { path: "/",component: <Dashboard/>},
  { path: "/account/profile",component: <Profile/>},
  { path: "/transactions/list",component: <ListTransactions/>},
  { path: "/vouchers/list",component: <ListVoucher/>},
  { path: "/vouchers/create",component: <CreateVoucher/>},
  { path: "/email/verify",component: <EmailPinCodeVerification/>},
  { path: "/payout/redeem",component: <RedeemPayout/>},
  { path :"*",component: <Basic404/>},
];

const publicRoutes = [
  { path: "/login",component: <Login/>},
  { path: "/register",component: <Register/>},
  { path: "/logout",component: <Logout/>},
  { path: "/reset/my/password",component: <ResetMyPassword/>},
  { path: "/password/reset/:token",component: <ResetPasswordPage/>},
  {path :"*",component: <Basic404/>},
];

export { authProtectedRoutes, publicRoutes };