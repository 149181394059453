import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Container, Spinner,} from 'reactstrap';

import Section1 from "../../project-pages/dashboard/Section1";
import CounterWidget from "../../project-pages/dashboard/CounterWidget";
import getApi from "../../apis/get.api";
import {toast} from "react-toastify";
import { getSystemCurrency } from 'common/commonFunction';

const Dashboard = (props : any) => {
    document.title = "Dashboard";
    const [report,setReport] = useState<any>(null)
    const [balance,setBalance] = useState<number>(0)
    const [vouchers,setVouchers] = useState<any>([])

    useEffect(() => {
        getApi('/reseller/reseller/dashboard').then((response) => {
            setReport(response.data.data.report)
            setBalance(response.data.data.balance)
        }).catch((error) => {
            toast.error('There was an issue while loading the page, please refresh the page')
        })

        getApi('/reseller/voucher/create').then((response) => {
            let vouchersData : any = [];
            response.data.data.vouchers.forEach((voucher : any) => {
                vouchersData.push({
                    voucherId : voucher.voucherId,
                    voucherCode : voucher.voucherCode,
                    voucherOrderId : voucher.voucherOrderId,
                    voucherAmount : voucher.voucherAmount,
                    voucherStatus : voucher.voucherStatus,
                    voucherExpiry : voucher.voucherExpiryDate,
                    voucherMerchantName : voucher.merchantName,
                    voucherCurrency : voucher.voucherCurrency
                })
            })
            setVouchers(vouchersData)
        }).catch((error) => {
            toast.error('There was an issue while loading the page, please refresh the page')
        })
    }, []);

    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                <Section1 balance={balance} changeDate={() => {}}/>
                <Card className='fw-bold'>
                    <CardHeader>
                        Report
                    </CardHeader>
                    <CardBody>
                        {report && <div className="table-responsive table-card card-body">
                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Currency</th>
                                        <th>Active</th>
                                        <th>Redeemed</th>
                                        <th>Deactivated</th>
                                        <th>Expired</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{getSystemCurrency()}</td>
                                        <td>{report.active}</td>
                                        <td>{report.redeemed}</td>
                                        <td>{report.deactivated}</td>
                                        <td>{report.expired}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                        {!report && <Spinner/>}
                    </CardBody>
                </Card>
                {report && <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="card fw-bold">
                            <div className="card-header">
                                Latest 10 Generated Vouchers
                            </div>
                            <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0 ">
                                    <thead className="table-light">
                                    <tr>
                                        <th scope="col">Voucher ID</th>
                                        <th scope="col">Voucher Merchant</th>
                                        <th scope="col">Voucher Code</th>
                                        <th scope="col">Voucher Expiration</th>
                                        <th scope="col">Voucher Amount</th>
                                        <th scope="col">Voucher Currency</th>
                                        <th scope="col">Voucher Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {vouchers && vouchers.length > 0 && vouchers.map((voucher: any,index : number) => {
                                        return <tr key={index}>
                                            <td><span className="fw-medium">#{voucher.voucherId}</span></td>
                                            <td>{voucher.voucherMerchantName}</td>
                                            <td>{voucher.voucherCode}</td>
                                            <td>{voucher.voucherExpiry}</td>
                                            <td>{voucher.voucherAmount}</td>
                                            <td>{voucher.voucherCurrency.toString().toUpperCase()}</td>
                                            <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                        </tr>
                                    })}
                                    {vouchers && vouchers.length === 0 && <tr>
                                        <td className="text-center p-4" colSpan={6}>No Vouchers Created Yet</td>
                                    </tr>}
                                    {!vouchers && <tr>
                                        <td className="text-center p-4" colSpan={6}><Spinner/></td>
                                    </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </Container>
    </div>
};

export default Dashboard;