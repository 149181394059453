import React, { useState } from 'react';
import { Input } from "reactstrap";
import postApi from "../../apis/post.api";
import {Link} from "react-router-dom";
import { handleError } from 'common/commonFunction';

const ResetMyPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSendResetLink = async () => {
        if (!email) {
            setError("Please enter your email.");
            return;
        }
        if (!validateEmail(email)) {
            setError("The email address is invalid. Please enter a valid email.");
            return;
        }
        setLoading(true);
        setError('');

        postApi('/forgot-password',{email : email}).then(response => {
            setSuccess(true);
            setEmail('')
        }).catch(error => {
            let message = 'Something went wrong while processing your request, please try again'
            if(error.response && error.response.data && error.response.data.message){
                message = error.response.data.message
            }
            setError(message);
            handleError(error)
        }).finally(() => {
            setLoading(false);
        })
    };

    const validateEmail = (email : string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <React.Fragment>
            <div className="w-100 mt-5">
                <div className="justify-content-center row">
                    <div className="col-12 col-xxl-4 col-xl-4 col-lg-6 col-md-8">
                        <div className="card p-5">
                            <div className="card-body text-center">
                                {!success ? (
                                    <>
                                        <h3>
                                            <i className="mdi mdi-lock" style={{ fontSize: "100px" }}></i>
                                        </h3>
                                        <h2 className="text-center">Forgot Password?</h2>
                                        <p>You can reset your password here.</p>
                                        {error && (
                                            <div className="alert alert-danger text-start" role="alert">
                                                {error}
                                            </div>
                                        )}
                                        <div className="form-group">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i className="mdi mdi-email"></i>
                                                </span>
                                                <Input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter Your Email"
                                                    aria-label="Enter Email"
                                                    aria-describedby="basic-addon2"
                                                    value={email}
                                                    disabled={loading}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <button
                                                className="btn btn-md btn-primary w-100"
                                                onClick={handleSendResetLink}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    "Send Reset Password Link"
                                                )}
                                            </button>
                                        </div>
                                    </>
                                ) : <>
                                    <div >
                                        <h1>
                                            <span className="mdi mdi-check-bold text-success" style={{fontSize: "100px"}}></span>
                                        </h1>
                                    </div>
                                    <div className="alert alert-success" role="alert">
                                        Reset password link has been sent to your email. Please check your inbox.
                                    </div>
                                    <Link className={"btn btn-primary w-100"} to={"/login"}> Back To Login Page</Link>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ResetMyPassword;
